import ImportExportTwoToneIcon from '@mui/icons-material/ImportExportTwoTone'
import { Roles } from '../roles'
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone'
import { lazyImport } from '../../hooks/LazyImport'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

const { ImportClickCeaseAccounts } = lazyImport(
  () => import('src/pages/dashboard/ImportClickCeaseAccounts'),
  'ImportClickCeaseAccounts'
)

const { CellXpertPage } = lazyImport(() => import('src/pages/dashboard/CellXpert'), 'CellXpertPage')

const { CreateAdminUserPage } = lazyImport(
  () => import('src/pages/dashboard/CreateAdminUser'),
  'CreateAdminUserPage'
)

export const OtherRoutes = [
  {
    name: 'Import ClickCease account',
    icon: <ImportExportTwoToneIcon />,
    route: '/dashboard/import-clickcease-account',
    path: 'import-clickcease-account/*',
    element: <ImportClickCeaseAccounts />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
    ],
  },
  {
    name: 'CellXpert',
    icon: <DisplaySettingsTwoToneIcon />,
    route: '/dashboard/cellxpert',
    path: 'cellxpert/*',
    element: <CellXpertPage />,
    permissions: [Roles['cheq-admin-t4'], Roles['marketing']],
  },
  {
    name: 'Create admin user',
    icon: <SupervisedUserCircleIcon />,
    route: '/dashboard/create-admin-user',
    path: 'create-admin-user/*',
    element: <CreateAdminUserPage />,
    permissions: [Roles['cheq-admin-t4']],
  },
]
