import { SidebarLayout } from 'src/layouts/SidebarLayout'
import { ProtectedRoutes } from 'src/consts/protectedRoutes'
import { isRouteAllowed } from 'src/utils/roles'
import { ProtectedRoute } from 'src/types/Routes'
import { Wrapper } from 'src/components/Wrapper'

const getProtectedChildrenArray = (adminRole: string) => {
  const routes = []
  ProtectedRoutes.forEach((route: ProtectedRoute) => {
    if (isRouteAllowed(adminRole, route.permissions) && route.path) {
      routes.push({
        path: route.path,
        element: <Wrapper />,
        children: [{ path: '*', element: route.element }],
      })
    }
    if (route.children) {
      route.children.forEach((child: ProtectedRoute) => {
        if (isRouteAllowed(adminRole, child.permissions) && child.path) {
          routes.push({
            path: child.path,
            element: <Wrapper />,
            children: [{ path: '*', element: child.element }],
          })
        }
      })
    }
  })
  return routes
}

export const getProtectedRoutes = (adminRole: string) => ({
  path: 'dashboard',
  element: <SidebarLayout />,
  children: !!adminRole && getProtectedChildrenArray(adminRole),
})

export const getNavigationProtectedRoutes = (adminRole: string) =>
  ProtectedRoutes.map((route: ProtectedRoute) =>
    isRouteAllowed(adminRole, route.permissions)
      ? {
          route: route.route,
          icon: route.icon,
          name: route.name,
          children: route.type === 'group' ? route.children : [],
        }
      : null
  ).filter((route) => route !== null)
