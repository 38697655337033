import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'

import { lazyImport } from 'src/hooks/LazyImport'
import { ProtectedRoute } from 'src/types/Routes'
import { Roles } from './roles'
import { AccountRoutes } from './RoutesChildren/AccountRoutes'
import { UserRoutes } from './RoutesChildren/UserRoutes'
import { ProductRoutes } from './RoutesChildren/ProductRoutes'
import { BillingRoutes } from './RoutesChildren/BillingRoutes'
import { OtherRoutes } from './RoutesChildren/OtherRoutes'
import PersonIcon from '@mui/icons-material/Person'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const { AccountImpersonationPage } = lazyImport(
  () => import('src/pages/dashboard/AccountImpersonation'),
  'AccountImpersonationPage'
)

export const ProtectedRoutes: ProtectedRoute[] = [
  {
    name: 'Agent mode',
    icon: <SupportAgentTwoToneIcon />,
    route: '/dashboard/agent-mode',
    path: 'agent-mode/*',
    element: <AccountImpersonationPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['marketing'],
      Roles['sales'],
    ],
  },
  {
    name: 'Account',
    type: 'group',
    route: '',
    path: '',
    icon: <AccountBoxIcon />,
    children: AccountRoutes,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['marketing'],
      Roles['sales'],
    ],
  },
  {
    name: 'User',
    type: 'group',
    icon: <PersonIcon />,
    route: '',
    path: '',
    children: UserRoutes,
    permissions: [
      Roles['cheq-admin-t4'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t1'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Billing',
    type: 'group',
    icon: <AttachMoneyIcon />,
    route: '',
    path: '',
    permissions: [Roles['cheq-admin-t2'], Roles['team-leader'], Roles['cheq-admin-t4']],
    children: BillingRoutes,
  },
  {
    name: 'Products',
    type: 'group',
    route: '',
    path: '',
    icon: <ShoppingCartCheckoutIcon />,
    permissions: [
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['sales'],
    ],
    children: ProductRoutes,
  },
  {
    name: 'Other',
    type: 'group',
    route: '',
    path: '',
    icon: <DynamicFeedIcon />,
    permissions: [
      Roles['marketing'],
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
    ],
    children: OtherRoutes,
  },
]
