import { removeCookie, setCookie } from 'typescript-cookie'
import { useUserStore } from 'src/stores/user'
import { COOKIE_NAMES } from 'src/consts/index'
import { LoginParamsType } from 'src/types/LoginParams'

export const useLogout = () => {
  const { logOut } = useUserStore()
  const logOutUser = () => {
    logOut()
    removeCookie(COOKIE_NAMES.token)
    removeCookie(COOKIE_NAMES.adminRole)
    removeCookie(COOKIE_NAMES.authCode)
    removeCookie(COOKIE_NAMES.email)
  }
  return { logOutUser }
}

export const useLogin = () => {
  const { logIn } = useUserStore()
  const populateAppWithLoginCredentials = async (loginCredentials: LoginParamsType) => {
    const { token, adminRole } = loginCredentials

    setCookie(COOKIE_NAMES.token, token, { expires: 0.5, secure: true })
    setCookie(COOKIE_NAMES.adminRole, adminRole)
    setCookie(COOKIE_NAMES.email, loginCredentials.email)
    logIn({ ...loginCredentials, email: loginCredentials.email })
  }

  return { populateAppWithLoginCredentials }
}
