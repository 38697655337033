import { create } from 'zustand'
import { LoginParamsType } from 'src/types/LoginParams'
import { getCookie } from 'typescript-cookie'
import { COOKIE_NAMES } from 'src/consts/'
import { devtools } from 'zustand/middleware'

export interface UserStore {
  isLoggedIn: boolean
  token: string
  adminRole: string
  email: string
  authCode: string
  errorMsg: string | null
  logIn: ({ token, adminRole, email }: LoginParamsType) => void
  logOut: () => void
  setAuthCode: (authCode: string) => void
  setToken: (token: string) => void
  setErrorMsg: (error: string | null) => void
}

const userStore = (set) => ({
  isLoggedIn: false,
  errorMsg: null,
  token: getCookie(COOKIE_NAMES.token) || '',
  adminRole: getCookie(COOKIE_NAMES.adminRole) || '',
  email: getCookie(COOKIE_NAMES.email) || '',
  authCode: getCookie(COOKIE_NAMES.authCode) || '',
  logIn: ({ token, adminRole, email }) => {
    set(() => ({
      token,
      adminRole,
      isLoggedIn: true,
      email,
    }))
  },
  logOut: () => {
    set(() => ({
      token: '',
      adminRole: '',
      isLoggedIn: false,
      email: '',
    }))
  },
  setToken: (token) => {
    set(() => ({
      token,
    }))
  },
  setAuthCode: (authCode) => {
    set(() => ({
      authCode,
    }))
  },
  setErrorMsg: (errorMsg) => {
    set(() => ({
      errorMsg,
    }))
  },
})

export const useUserStore = create(devtools(userStore))
export default useUserStore
