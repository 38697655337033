import { Roles, RolesName } from 'src/consts/roles'

const AdminRoleMap = {
  'cheq-admin-t1': ['plg', 'marketing', 'sales', 'cheq-admin-t1'],
  'cheq-admin-t2': ['plg', 'marketing', 'sales', 'cheq-admin-t1', 'cheq-admin-t2'],
  'cheq-admin-t4': [
    'plg',
    'marketing',
    'sales',
    'cheq-admin-t1',
    'cheq-admin-t2',
    'team-leader',
    'cheq-admin-t4',
  ],
  'team-leader': ['plg', 'marketing', 'sales', 'cheq-admin-t1', 'cheq-admin-t2', 'team-leader'],
  marketing: ['marketing'],
  sales: ['sales'],
  plg: ['plg'],
}

export const isRouteAllowed = (role: string, routePremissions: string[]) => {
  if (role) {
    return AdminRoleMap[role].some((r: string) => routePremissions.includes(r))
  }
  return []
}

export function getRoleName(role: string) {
  return RolesName[role.toLowerCase()] ?? ''
}
