import React from 'react'
import { lazyImport } from 'src/hooks/LazyImport'

import { BaseLayout } from 'src/layouts/BaseLayout'
import useUserStore from 'src/stores/user'

const { token } = useUserStore.getState()

const { Login } = lazyImport(() => import('src/pages/Login'), 'Login')
const { Status404 } = lazyImport(() => import('src/pages/Status404'), 'Status404')
export const publicRoutes = {
  path: '',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: <Login />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '*',
      element: token ? <Status404 /> : <Login />,
    },
  ],
}
