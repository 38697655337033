import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Roles } from '../roles'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart'
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone'
import { lazyImport } from '../../hooks/LazyImport'
import DomainAddIcon from '@mui/icons-material/DomainAdd'

const { CustomProductsPage } = lazyImport(
  () => import('src/pages/dashboard/CustomProducts'),
  'CustomProductsPage'
)

const { RemoveProductPage } = lazyImport(
  () => import('src/pages/dashboard/RemoveProduct'),
  'RemoveProductPage'
)

const { CreateNewProductPage } = lazyImport(
  () => import('src/pages/dashboard/CreateNewProduct'),
  'CreateNewProductPage'
)

const { PredefinedProductsPage } = lazyImport(
  () => import('src/pages/dashboard/PredefinedProducts'),
  'PredefinedProductsPage'
)

const { FeatureManagerPage } = lazyImport(
  () => import('src/pages/dashboard/FeaturesManager'),
  'FeatureManagerPage'
)

const { AddDomainAllowancePage } = lazyImport(
  () => import('src/pages/dashboard/AddDomainAllowance'),
  'AddDomainAllowancePage'
)

export const ProductRoutes = [
  {
    name: 'Create new product',
    icon: <AddShoppingCartIcon />,
    route: '/dashboard/create-new-product',
    path: 'create-new-product/*',
    element: <CreateNewProductPage />,
    permissions: [Roles['cheq-admin-t2'], Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Add domain allowance',
    icon: <DomainAddIcon />,
    route: '/dashboard/add-domain-allowance',
    path: 'add-domain-allowance/*',
    element: <AddDomainAllowancePage />,
    permissions: [
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Update Products - Predefined Products',
    icon: <ShoppingCartCheckoutIcon />,
    route: '/dashboard/predefined-products',
    path: 'predefined-products/*',
    element: <PredefinedProductsPage />,
    permissions: [
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Update Products - Custom Products',
    icon: <ShoppingCartCheckoutIcon />,
    route: '/dashboard/custom-products',
    path: 'custom-products/*',
    element: <CustomProductsPage />,
    permissions: [Roles['cheq-admin-t2'], Roles['cheq-admin-t4'], Roles['team-leader']],
  },
  {
    name: 'Update Products - Remove Products',
    icon: <RemoveShoppingCartIcon />,
    route: '/dashboard/remove-product',
    path: 'remove-product/*',
    element: <RemoveProductPage />,
    permissions: [
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Features manager',
    icon: <SwipeUpTwoToneIcon />,
    route: '/dashboard/features-manager',
    path: 'features-manager/*',
    element: <FeatureManagerPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
    ],
  },
]
