import { ProtectedRoute } from '../../types/Routes'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { Roles } from '../roles'
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone'
import { lazyImport } from '../../hooks/LazyImport'
import WebAssetIcon from '@mui/icons-material/WebAsset'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'

const { ControlGoogleConnectionPage } = lazyImport(
  () => import('src/pages/dashboard/ControlGoogleConnection'),
  'ControlGoogleConnectionPage'
)

const { ResendPasswordPage } = lazyImport(
  () => import('src/pages/dashboard/ResendPassword'),
  'ResendPasswordPage'
)

const { Generate2FaTokenPage } = lazyImport(
  () => import('src/pages/dashboard/Generate2FaToken'),
  'Generate2FaTokenPage'
)

const { ChangeDomainOwnerPage } = lazyImport(
  () => import('src/pages/dashboard/ChangeDomainOwner'),
  'ChangeDomainOwnerPage'
)

export const UserRoutes: ProtectedRoute[] = [
  {
    name: 'Resend Password',
    icon: <ForwardToInboxIcon />,
    route: '/dashboard/resend-password',
    path: 'resend-password/*',
    element: <ResendPasswordPage />,
    permissions: [
      Roles['cheq-admin-t4'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t1'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Generate 2FA code',
    icon: <ShieldTwoToneIcon />,
    route: '/dashboard/generate-2fa-code',
    path: 'generate-2fa-code/*',
    element: <Generate2FaTokenPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
      Roles['sales'],
    ],
  },
  {
    name: 'Change domain owner',
    icon: <WebAssetIcon />,
    route: '/dashboard/change-domain-owner',
    path: 'change-domain-owner/*',
    element: <ChangeDomainOwnerPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
    ],
  },
  {
    name: 'Control Google connection',
    icon: <SettingsTwoToneIcon />,
    route: '/dashboard/control-google-connection',
    path: 'control-google-connection/*',
    element: <ControlGoogleConnectionPage />,
    permissions: [
      Roles['cheq-admin-t1'],
      Roles['cheq-admin-t2'],
      Roles['cheq-admin-t4'],
      Roles['team-leader'],
    ],
  },
]
