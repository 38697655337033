import { useContext, useState } from 'react'
import { alpha, Box, List, styled, Button, ListItem } from '@mui/material'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { SidebarContext } from 'src/contexts/SidebarContext'
import { getNavigationProtectedRoutes } from 'src/HOC/Routes/protected.routes'
import useUserStore from 'src/stores/user'
import { isRouteAllowed } from '../../../../utils/roles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
)

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
  
  
    .menu-item-wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }  
    .subMenu{
      margin-left:24px
    }
    .MuiList-root {
        padding: 0;

      .MuiListItem-root {
        padding: 1px 0;
        display:flex;
        flex-direction: column;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.activeRoute{
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
          }
     
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
)

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext)
  const { adminRole } = useUserStore()
  const routes = getNavigationProtectedRoutes(adminRole)
  const [selectedSubMenu, setSelectedSubMenu] = useState('')
  const location = useLocation()

  const itemClicked = (item) => {
    closeSidebar()
    if (item.children) {
      setSelectedSubMenu(item.name)
    }
  }

  const renderSubMenu = (item) => {
    return item.children.map((subItem) => {
      return isRouteAllowed(adminRole, subItem.permissions) ? (
        <div className={'subMenu'} key={subItem.route}>
          <Button
            className={location.pathname === subItem.route ? 'activeRoute' : ''}
            disableRipple
            component={RouterLink}
            onClick={() => itemClicked(subItem)}
            to={subItem.route}
            startIcon={subItem.icon}
          >
            {subItem.name}
          </Button>
        </div>
      ) : null
    })
  }

  const renderArrow = (item) => {
    return selectedSubMenu === item.name ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
  }

  return (
    <MenuWrapper>
      <List component="div">
        <SubMenuWrapper>
          <List component="div">
            {routes.map((item) => {
              return (
                <ListItem component="div" key={item.route}>
                  <Button
                    className={location.pathname === item.route ? 'activeRoute' : ''}
                    disableRipple
                    component={RouterLink}
                    onClick={() => itemClicked(item)}
                    to={item.route ? item.route : '#'}
                    startIcon={item.icon}
                  >
                    <div className={'menu-item-wrapper'}>
                      <div className={'menu-item-text'}>{item.name}</div>
                      {item.children.length > 0 && renderArrow(item)}
                    </div>
                  </Button>
                  {item.children && selectedSubMenu === item.name && (
                    <List component="div">{renderSubMenu(item)}</List>
                  )}
                </ListItem>
              )
            })}
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  )
}

export default SidebarMenu
