export const Roles = {
  'cheq-admin-t1': 'cheq-admin-t1',
  'cheq-admin-t2': 'cheq-admin-t2',
  'cheq-admin-t3': 'cheq-admin-t3',
  'cheq-admin-t4': 'cheq-admin-t4',
  'team-leader': 'team-leader',
  marketing: 'marketing',
  sales: 'sales',
  plg: 'plg',
}

export const RolesName = {
  'cheq-admin-t1': 'Support T1',
  'cheq-admin-t2': 'Support T2',
  'cheq-admin-t3': 'Support T3',
  'cheq-admin-t4': 'Administrator',
  'team-leader': 'Team Leader',
  marketing: 'Marketing',
  sales: 'Sales',
  plg: 'PLG',
}
