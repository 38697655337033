import { useContext, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Drawer,
  Button,
  List,
  ListItem,
} from '@mui/material'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'

import HeaderUserbox from './Userbox'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { getNavigationProtectedRoutes } from 'src/HOC/Routes/protected.routes'
import useUserStore from 'src/stores/user'
import { isRouteAllowed } from '../../../utils/roles'

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
)

function Header() {
  const navigate = useNavigate()
  const { error } = useAuth0()
  if (error) {
    const { logout } = useAuth0()
    logout()
    navigate('/')
  }

  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (newOpen) => () => setOpen(newOpen)
  const theme = useTheme()
  const { user } = useAuth0()
  const { adminRole } = useUserStore()
  const routes = getNavigationProtectedRoutes(adminRole)
  const [selectedSubMenu, setSelectedSubMenu] = useState('')

  const itemClicked = (item) => {
    if (item.children) {
      setSelectedSubMenu(item.name)
    }
  }

  const subItemClicked = () => {
    setOpen(false)
  }

  const renderSubMenu = (item) => {
    return item.children.map((subItem) => {
      return isRouteAllowed(adminRole, subItem.permissions) ? (
        <div className={'subMenu'} key={subItem.route}>
          <Button
            className={location.pathname === subItem.route ? 'activeRoute' : ''}
            disableRipple
            component={RouterLink}
            onClick={() => subItemClicked()}
            to={subItem.route}
            startIcon={subItem.icon}
          >
            {subItem.name}
          </Button>
        </div>
      ) : null
    })
  }

  const list = () => (
    <Box role="presentation" onKeyDown={toggleDrawer(false)}>
      <List component="div">
        {routes.map((item, index) => (
          <React.Fragment key={item.route}>
            <ListItem component="div">
              <Button
                component={RouterLink}
                onClick={() => itemClicked(item)}
                to={item.route ? item.route : '#'}
                startIcon={item.icon}
              >
                {item.name}
                {item.children && selectedSubMenu === item.name && (
                  <List component="div">{renderSubMenu(item)}</List>
                )}
              </Button>
            </ListItem>
            {index !== routes.length - 1 && <Divider />}
            {/* Render Divider for all items except the last one */}
          </React.Fragment>
        ))}
      </List>
    </Box>
  )

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`,
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      ></Stack>
      <Box display="flex" alignItems="center">
        {user && <HeaderUserbox />}
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' },
          }}
        >
          {!open && (
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleDrawer(true)}>
                <Button>
                  <MenuTwoToneIcon fontSize="small" />
                </Button>
              </IconButton>
            </Tooltip>
          )}
          <Drawer open={open} anchor={'right'} onClose={toggleDrawer(false)}>
            {list()}
          </Drawer>
        </Box>
      </Box>
    </HeaderWrapper>
  )
}

export default Header
