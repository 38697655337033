import { Box, CircularProgress } from '@mui/material'
import { useAppStore } from 'src/stores/app'

function ApiLoader() {
  const { isLoading } = useAppStore()
  return (
    <Box
      sx={{
        display: isLoading ? 'flex' : 'none',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 999,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.2)',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="warning" size={'10vh'} disableShrink thickness={4} />
    </Box>
  )
}

export default ApiLoader
